
<script setup>
import { useI18n } from 'vue-i18n';


const emit = defineEmits(['update:modelValue']);

import { ref } from 'vue';
// Import FilePond
import vueFilePond from 'vue-filepond';

// Import plugins
import FilePondPluginFileValidateType
    from 'filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm.js';

// Import styles
import 'filepond/dist/filepond.min.css';


let props = defineProps({
    modelValue: Object, color: {
        type: String,
        default: 'default',
        validator(value) {
            // The value must match one of these strings
            return ['default', 'pink'].includes(value);
        },
    },
    acceptedFiles: {
        type: String,
        default: 'image/jpeg, image/png',
    },
    innerLabel: {
        type: String,
        default: 'Press to upload a file',
    },
});
const pond = ref(null);

// Create FilePond component
const FilePond = vueFilePond(FilePondPluginFileValidateType);

function handleFilePondInit() {

    emit('update:modelValue', pond.value.getFiles());
}

</script>

<template>
    <file-pond
        ref='pond' :allow-multiple='false'
        :accepted-file-types='acceptedFiles'
        class='uppercase' :class="color === 'pink' ? 'pink' : ''"
        :label-idle='innerLabel'
        v-bind:files='modelValue'
        v-on:input='handleFilePondInit'
    />

</template>


<style>


.filepond--credits {
    display: none;
}

    .pink .filepond--panel-root {
        background-color: #FF0F7B;
    }

    .pink .filepond--drop-label {
        color: white;
    }



.filepond--root .filepond--drop-label {
    min-height: 3em;
    font-size: 0.875rem; /* 14px */
    line-height: 1.25rem;
}

.filepond--root .filepond--drop-label label {
    font-weight: unset;
}

@media (min-width: 640px) {

    .filepond--root .filepond--drop-label {
        min-height: 2em;
        font-size: 1.25rem; /* 20px */
        line-height: 1.75rem; /* 28px */
    }

}


</style>
